import React, { useRef, useState, forwardRef, useImperativeHandle, useContext, useEffect } from 'react';
import { SidebarBody, SidebarRefObject, SidebarProps, Sidebar } from '@paljs/ui/Sidebar';
import { Menu, MenuRefObject } from '@paljs/ui/Menu';
import { Button } from '@paljs/ui/Button';
import { EvaIcon } from '@paljs/ui/Icon';
import menuItems from '../menuItem';
import { Link } from 'gatsby';
import { Location } from '@reach/router';
import '../../styles/common.css';
import { GlobalStateData } from '../../storage/GlobalDataProvider';
import { getPageAccess, navBarHeadingMap, screenHeadingMap } from '../../utils/utils';
import { MenuItemType } from '@paljs/ui';

export const getPathReady = (path: string) => {
  return path.endsWith('/') ? path.slice(0, -1) : path;
};

const SidebarCustom: React.ForwardRefRenderFunction<Omit<SidebarRefObject, 'hide'>, SidebarProps> = (props, ref) => {
  const [menuState, setMenuState] = useState(false);
  const sidebarRef = useRef<SidebarRefObject>(null);
  const menuRef = useRef<MenuRefObject>(null);
  const [seeHeader, setSeeHeader] = useState(true);
  const authState = useContext(GlobalStateData);
  const [currMenuItems, setCurrMenuItems] = useState([]);
  const [pageAccessData, setPageAccessData] = useState(authState);
  const menuItemsGenerator = () => {
    let startIdx = -1;
    const menuItems: MenuItemType[] = [];
    if (pageAccessData.dashboard.read) {
      menuItems.push({
        title: 'Home Page',
        icon: { name: 'home' },
        link: { to: '/dashboard' },
      });
      startIdx++;
    }
    menuItems.push(
      {
        title: 'Master',
        icon: { name: 'briefcase-outline' },
        children: [],
      },
      {
        title: 'Transaction',
        icon: { name: 'sync-outline' },
        children: [],
      },
      {
        title: 'Reports',
        icon: { name: 'bar-chart-2-outline' },
        children: [],
      },
    );
    for (const key in pageAccessData) {
      if (pageAccessData[key]?.read === 1) {
        if (screenHeadingMap[key].match(/master\/.*/)) {
          menuItems[startIdx + 1].children?.push(navBarHeadingMap[key]);
        } else if (screenHeadingMap[key].match(/transaction\/.*/)) {
          menuItems[startIdx + 2].children?.push(navBarHeadingMap[key]);
        } else if (screenHeadingMap[key].match(/reports\/.*/)) {
          menuItems[startIdx + 3].children?.push(navBarHeadingMap[key]);
        }
      }
    }
    if (authState.authEmail === 'ublmtp@gmail.com') {
      menuItems.push({
        title: 'Admin',
        icon: { name: 'lock-outline' },
        children: [
          {
            title: 'Create Account',
            link: { to: '/admin/create-account' },
          },
          {
            title: 'Reset Password',
            link: { to: '/admin/reset-password' },
          },
          {
            title: 'Screen Access',
            link: { to: '/admin/screen-access' },
          },
        ],
      });
    }
    const finalMenuItems = [];
    menuItems.forEach((t, i) => {
      if (t?.children?.length !== 0) {
        finalMenuItems.push(t);
      }
    });
    setCurrMenuItems(finalMenuItems);
  };
  useImperativeHandle(ref, () => ({
    toggle() {
      sidebarRef.current?.toggle();
    },
  }));

  const getState = (state?: 'hidden' | 'visible' | 'compacted' | 'expanded') => {
    setSeeHeader(state !== 'compacted');
  };

  useEffect(() => {
    setPageAccessData(authState.pageAccess);
  }, [authState]);

  useEffect(() => {
    if (pageAccessData?.graStock) {
      menuItemsGenerator();
    }
  }, [pageAccessData]);

  return (
    <Sidebar getState={getState} ref={sidebarRef} property="start" containerFixed responsive className="menu-sidebar">
      {seeHeader && (
        <header>
          <Button
            size="Tiny"
            status="Primary"
            onClick={() => {
              setMenuState(!menuState);
              menuRef.current?.toggle();
            }}
            fullWidth
            className="togg-color"
          >
            {menuState ? <EvaIcon name="arrow-circle-up" /> : <EvaIcon name="arrow-circle-down" />}
          </Button>
        </header>
      )}
      <SidebarBody>
        <Location>
          {({ location }) => (
            <Menu
              className="sidebar-menu"
              Link={Link}
              ref={menuRef}
              items={currMenuItems}
              currentPath={getPathReady(location.pathname)}
              toggleSidebar={() => sidebarRef.current?.hide()}
            />
          )}
        </Location>
      </SidebarBody>
    </Sidebar>
  );
};

export default forwardRef(SidebarCustom);
