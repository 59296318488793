import React, { useEffect } from "react";
import { authCheck } from "../utils/utils";

export const GlobalStateData = React.createContext()
export const GlobalDispatchData = React.createContext()

export const isBrowser = () => typeof window !== "undefined"

const initialState = {
    authData: (isBrowser() && localStorage.getItem('authData')) ? localStorage.getItem('authData') : '',
    authName: (isBrowser() && localStorage.getItem('authName')) ? localStorage.getItem('authName') : '',
    authEmail: (isBrowser() && localStorage.getItem('authEmail')) ? localStorage.getItem('authEmail') : '',
    authRole: (isBrowser() && localStorage.getItem('authRole')) ? localStorage.getItem('authRole') : '',
    authId: (isBrowser() && localStorage.getItem('authId')) ? localStorage.getItem('authId') : '',
    pageAccess: ''
}

function reducer(state, action) {
    switch (action.type) {
        case 'APP_LOGIN': {
            localStorage.setItem('authData', action.payload.jwt)
            localStorage.setItem('authName', action.payload.user.name)
            localStorage.setItem('authEmail', action.payload.user.email)
            localStorage.setItem('authRole', action.payload.user.userType)
            localStorage.setItem('authId', action.payload.user.id)
            return {
                ...state,
                authData: action.payload.jwt,
                authName: action.payload.user.name,
                authEmail: action.payload.user.email,
                authRole: action.payload.user.userType,
                authId: action.payload.user.id,
            }
        }
        case 'APP_LOGOUT': {
            localStorage.setItem('authData', '')
            localStorage.setItem('authName', '')
            localStorage.setItem('authEmail', '')
            localStorage.setItem('authRole', '')
            localStorage.setItem('authId', '')
            return {
                ...state,
                authData: '',
                authName: '',
                authEmail: '',
                authRole: '',
                authId: '',
                pageAccess: ''
            }
        }
        case 'PAGE_ACCESS': {
            return {
                ...state,
                pageAccess: action.payload.pageAccess
            }
        }
        default:
            throw new Error('Bad action type')
    }
}

const GlobalDataProvider = ({ children }) => {
    const [state, dispatch] = React.useReducer(reducer, initialState)
    useEffect(()=>{
        if(!state.authData) authCheck(state.authData);
    },[state]);
    return (<GlobalStateData.Provider value={state}>
        <GlobalDispatchData.Provider value={dispatch}>
            {children}
        </GlobalDispatchData.Provider>
    </GlobalStateData.Provider>)
}

export default GlobalDataProvider;