import React, { useState, useRef, useEffect, useContext } from 'react';
import { DefaultTheme, ThemeProvider } from 'styled-components';
import themes from './themes';
import { Layout, LayoutContent, LayoutFooter, LayoutContainer, LayoutColumns, LayoutColumn } from '@paljs/ui/Layout';
import icons from '@paljs/icons';
import { SidebarRefObject } from '@paljs/ui/Sidebar';
import Header from './Header';
import SimpleLayout from './SimpleLayout';
import SidebarCustom from './Sidebar';
import axios from 'axios';
import { navigate } from '@reach/router';
import { GlobalDispatchData, GlobalStateData } from '../storage/GlobalDataProvider';
import { checkViewAccess, getPageAccess, showLoader } from '../utils/utils';
import Error from '../pages/404';
import { isBrowser } from '../pages';
import { useIdleTimer } from 'react-idle-timer';

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status == 401) {
      navigate('/logout');
    } else {
      return Promise.reject(error);
    }
  },
);

const getDefaultTheme = (): DefaultTheme['name'] => {
  if (typeof localStorage !== 'undefined' && localStorage.getItem('theme')) {
    return localStorage.getItem('theme') as DefaultTheme['name'];
  } else {
    const hours = new Date().getHours();
    return hours > 6 && hours < 19 ? 'default' : 'default';
  }
};

const LayoutPage: React.FC<{ pageContext: { layout: string } }> = ({ children, pageContext }) => {
  const [theme, setTheme] = useState<DefaultTheme['name']>('default');
  const [dir, setDir] = useState<'ltr' | 'rtl'>('ltr');
  const sidebarRef = useRef<SidebarRefObject>(null);
  const state = useContext(GlobalStateData);
  const dispatch = useContext(GlobalDispatchData);
  useIdleTimer({
    timeout: 900000,
    disabled: !state.authData,
    crossTab: true,
    onIdle: () => {
      dispatch({ type: 'APP_LOGOUT' });
    },
  });
  const changeTheme = (newTheme: DefaultTheme['name']) => {
    setTheme(newTheme);
    typeof localStorage !== 'undefined' && localStorage.setItem('theme', newTheme);
  };
  const isAccessible = checkViewAccess(state);
  useEffect(() => {
    const localTheme = getDefaultTheme();
    if (localTheme !== theme && theme === 'default') {
      setTheme(localTheme);
    }
  }, []);

  useEffect(() => {
    const pageAccessGenerator = async () => {
      let pageAccess = await getPageAccess(state.authId, state.authData, true);
      dispatch({ type: 'PAGE_ACCESS', payload: { pageAccess } });
    };
    if (state.authData && state.pageAccess === '') {
      pageAccessGenerator();
    }
  }, [state]);
  const changeDir = () => {
    const newDir = dir === 'ltr' ? 'rtl' : 'ltr';
    setDir(newDir);
  };
  return (
    <ThemeProvider theme={themes(theme, dir)}>
      <div id="globalLoader">
        <span></span>
      </div>
      <>
        <SimpleLayout />
        <Layout evaIcons={icons} dir={dir} className={pageContext.layout === 'auth' ? 'auth-layout' : ''}>
          {pageContext.layout !== 'auth' && (
            <Header
              dir={dir}
              changeDir={changeDir}
              theme={{ set: changeTheme, value: theme }}
              toggleSidebar={() => sidebarRef.current?.toggle()}
            />
          )}
          <LayoutContainer>
            {pageContext.layout !== 'auth' && <SidebarCustom ref={sidebarRef} />}
            <LayoutContent>
              <LayoutColumns>
                <LayoutColumn className="main-content">
                  <>
                    {!isBrowser() && children}
                    {isBrowser() && isAccessible && children}
                    {isBrowser() && !isAccessible && state.pageAccess !== '' && <Error />}
                  </>
                </LayoutColumn>
              </LayoutColumns>
            </LayoutContent>
          </LayoutContainer>
        </Layout>
      </>
    </ThemeProvider>
  );
};

export default LayoutPage;
