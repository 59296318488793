import React, { useEffect, useContext } from 'react';
import { navigate } from 'gatsby';
import { GlobalStateData } from '../storage/GlobalDataProvider';
import { authCheck, hideLoader } from '../utils/utils';
import '../styles/common.css';
import '../styles/select2.css';
export const isBrowser = () => typeof window !== 'undefined';

export default function Index() {
  const state = useContext(GlobalStateData);
  useEffect(() => {
    authCheck(state.authData);
    hideLoader();
  }, []);
  return <div />;
}
